import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { useEffect, useState } from "react";
import { isTaggedTemplateExpression } from "typescript";
import { store } from "../../context/store";

import Config from "../../Config";

export interface RootObject {
	Identification: string;
	Date: Date;
	State: IState;
	User: string;
	Location: string;
}

interface IState {
	index?: number;
	name?: string | undefined;
	color?: string | undefined;
	hex?: string | undefined;
	light?: string | undefined;
}

interface IChatState {
	connection: HubConnection | null;
}

const Chat = () => {
	const [state, setState] = useState<IChatState>({
		connection: null,
	});

	const chatHubUrl: string = Config.ChatHubUrl as string;

	useEffect(() => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(chatHubUrl)
			.withAutomaticReconnect()
			.build();
		setState({ connection: newConnection });
	}, []);

	useEffect(() => {
		if (state.connection) {
			state.connection
				.start()
				.then((result) => {
					console.log("Connected!");
					try {
						state.connection!.on(
							"ReceiveMessage",
							(message: string, increment: number) => {
								if (message && increment) {
									const scanData: RootObject =
										JSON.parse(message);
									store.dispatch({
										type: "SET_SCANDATA",
										payload: scanData,
										increment: increment,
									});
								}
							}
						);
						state.connection!.on(
							"UpdateQRDashboard",
							(message: string) => {
								if (message) {
									// store.dispatch({
									// 	type: "SET_SPECKLE_MODEL_URL",
									// 	payload: message,
									// });
								}
							}
						);
						state.connection!.on(
							"ResetDashboard",
							(message: string) => {
								if (message) {
									store.dispatch({
										type: "RESET_ACTION",
										payload: true,
									});
								}
							}
						);
					} catch (error: any) {
						console.log(error.message);
					}
				})
				.catch((error: any) =>
					console.log("Connection failed: ", error)
				);
		}
	}, [state.connection]);

	return <div />;
};
export default Chat;
