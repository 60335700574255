//React
import React, { createRef, useRef } from "react";
//components
import Navbar from "./navbar";
import ProgressTracking from "./dashboardComponents/progressTracking";
import ReportBuilder from "./dashboardComponents/reportBuilder";
import BIMExperienceCenter from "./dashboardComponents/bimExperienceCenter";
import ProgressPanel from "./dashboardComponents/progress/progressPanel";
import SolutionsPanel from "./dashboardComponents/solutions/solutionsPanel";
import Piechart from "./dashboardComponents/charts/piechart";
import Tablist from "./dashboardComponents/tablist/tablist";
import ViewerComponent from "./dashboardComponents/viewer/viewer";
import UpdatesPanel from "./dashboardComponents/updatesPanel";
import ChatHub from "./chatHub/ChatHub";
import { shallowEqual, useSelector } from "react-redux";
import { IReducerState } from "../context/Reducer";

import Config from "../Config";

const Dashboard = () => {
	const reducerState: any = useSelector<IReducerState>(
		(reducerState: IReducerState) => reducerState,
		shallowEqual
	);

	const speckleUrl: string = Config.SpeckleUrl as string;

	return (
		<div>
			<ChatHub />
			<Navbar />
			<div style={{ marginTop: "130px" }}>
				<div
					className="container-fluid"
					style={{ display: "flex", height: "80vh" }}
				>
					<div className="row">
						<div className="card-box col-xl-6 col-lg-6 col-md-6">
							<div className="row">
								<ProgressTracking />
								<ReportBuilder />
								<div className="row">
									<BIMExperienceCenter />
									<ProgressPanel />
									<SolutionsPanel />
									{Object.keys(
										reducerState.statesProgress
									).map((state) => {
										return (
											<Piechart
												header={state}
												count={
													reducerState.statesProgress[
														state
													].count
												}
												color={
													reducerState.statesProgress[
														state
													].color
												}
											/>
										);
									})}
								</div>
							</div>
						</div>
						<div
							className="col-sm-12 col-md-6 col-lg-6 col-xl-6"
							style={{ marginBottom: "10px" }}
						>
							<ViewerComponent speckleUrl={speckleUrl} />
							<div id="info" className="revitID">
								<p id="pshowID" style={{ display: "none" }}></p>
							</div>
							<div className="row">
								<div className="col-xl-12 col-md-12">
									<UpdatesPanel />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
export default Dashboard;
