import React, { useEffect, useState } from "react";

import { Viewer } from "@speckle/viewer";
import { shallowEqual, useSelector } from "react-redux";
import { IReducerState } from "../../../context/Reducer";
import ViewerObject from "./viewerObject";
import Swal from "sweetalert2";

import Config from "../../../Config";

interface IViewerComponentProps {
	speckleUrl: string;
}

interface IViewerState {
	url: string;
}

interface IViewerComponentState {
	viewer: any;
	prevModelUrl: string;
}

const ViewerComponent: React.FC<IViewerComponentProps> = (props) => {
	const [state, setState] = useState<IViewerComponentState>({
		viewer: null,
		prevModelUrl: "",
	});
	const modelUrl: any = useSelector<IReducerState>(
		(state: IReducerState) => state.speckleModelUrl
	);
	let scanData: any = useSelector<IReducerState>(
		(state: IReducerState) => state.scanData,
		shallowEqual
	);
	let resetUpdates: any = useSelector<IReducerState>(
		(state: IReducerState) => state.resetUpdates,
		shallowEqual
	);
	const zoomFactor: number = Number(Config.zoomFactor as string);
	const objectToZoomId: string = Config.ObjectToZoomId as string;

	/**
	 * Adds an loading popup on the viewer
	 * See the CSS-classes {vi newer-swal-container, viewer-swal-loader, viewer-swal-popup} for designing the viewer
	 */
	const addLoadingPopup = () => {
		Swal.fire({
			title: "",
			target: "#renderer",
			customClass: {
				container: "viewer-swal-container",
				loader: "viewer-swal-loader",
				popup: "viewer-swal-popup",
			},
			allowEscapeKey: false,
			allowEnterKey: false,
		});
		let btn = Swal.getConfirmButton();
		if (btn) {
			Swal.showLoading(btn);
		}
	};

	/**
	 * Colors objects in the viewer with a specific id by a specific color.
	 * @param viewerObjects All the objects which should be colored by id.
	 * @param defaultColor The default color which is used for all other objects in the model. (Can be null in the future to keep colors see: SpeckleForums https://speckle.community/t/speckleviewer-color-objects-without-ghosting-others/2308/7)
	 */
	const colorByIds = (
		viewerObjects: Array<ViewerObject>,// = [new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79df", "blue"), new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79fe","red"), new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e2", "black")],
		defaultColor: string = "grey"
	) => {
		const mappingObjects: any = {};
		viewerObjects.forEach((x) => {
			mappingObjects[x.id] = x.color;
		});
		state.viewer.applyFilter({
			colorBy: {
				property: "applicationId",
				type: "category",
				values: mappingObjects,
				default: null,
			},
		});
	};

	const zoomToObjectId = (id: string) => {
		let obj = state.viewer.sceneManager.allObjects.find(
			(o: { userData: { applicationId: string } }) =>
				o.userData.applicationId === id
		);
		if (obj) {
			state.viewer.interactions.zoomToObject(obj, zoomFactor, false);
		} else {
			console.warn(`No object with id of ${id} found.`);
		}
	};

	const adjustViewPoint = () => {
		zoomToObjectId(objectToZoomId);
		const DEG90 = Math.PI * 0.5
		const negDEG90 = Math.PI * -0.2
		state.viewer.interactions.rotateTo("bottom", false);
        state.viewer.cameraHandler.controls.rotateTo(negDEG90, DEG90, false)
		state.viewer.cameraHandler.controls.dollyToCursor = true;
		state.viewer.needsRender = true;
		Swal.close();
	};

	useEffect(() => {
		if(!resetUpdates || !state.viewer){
			return;
		}
		const color: string = "#fff"
		//TODO: dirty solution. Make dynamic later.
		const objectsToColor: ViewerObject[] = [
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79fc", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79df", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79fe", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e2", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c7a03", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c7a06", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79de", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e5", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e7", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79eb", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79ee", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79f2", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e4", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79ea", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79f6", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c7a00", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e6", color),
		];
		viewerObjects.forEach((viewerObject: ViewerObject) => {
			objectsToColor.push(viewerObject);
		})
		colorByIds(objectsToColor)
	}, [resetUpdates])

	useEffect(() => {
		const rendererDiv = document.getElementById("renderer");
		if (!rendererDiv) {
			return;
		}
		if (!state.viewer) {
			const viewer = new Viewer({
				container: rendererDiv,
				showStats: false,
			});

			setState({ viewer: viewer, prevModelUrl: state.prevModelUrl });
			return;
		}
		addLoadingPopup();
		console.log(props.speckleUrl)
		state.viewer.loadObject(props.speckleUrl).then(async () => {
			adjustViewPoint();
			colorByIds(viewerObjects)
		});
	}, [state.viewer]);

	useEffect(() =>{
		if(!scanData || !scanData.Identification || !scanData.State.hex ){
			return;
		}
		const color: string = scanData.State.hex;
		//TODO: dirty solution. Make dynamic later.
		const objectsToColor: ViewerObject[] = [
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79fc", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79df", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79fe", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e2", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c7a03", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c7a06", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79de", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e5", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e7", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79eb", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79ee", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79f2", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e4", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79ea", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79f6", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c7a00", color),
			new ViewerObject("1879dbcb-36bc-4c4c-8006-ee356e994080-000c79e6", color),
		];
		viewerObjects.forEach((viewerObject: ViewerObject) => {
			objectsToColor.push(viewerObject);
		})
		colorByIds(objectsToColor)
	}, [scanData])

	const loadNewModel = async () => {
		await state.viewer.unloadAll();
		await state.viewer.loadObject(modelUrl);
		adjustViewPoint();
		await state.viewer.unloadObject(state.prevModelUrl);
	};

	if (modelUrl !== "" && state.viewer && modelUrl !== state.prevModelUrl) {
		addLoadingPopup();
		loadNewModel();
		setState({ viewer: state.viewer, prevModelUrl: modelUrl });
	}

	return (
		<div className="viewer-debug">
			<div id="renderer" />
		</div>
	);
};

const pipeRed: string = "#dc3545";
const pipeBlue: string = "#007bff";
const ductGreen: string = "#28a745";
const revitElementOrange: string = "#fd7e14"; 

const viewerObjects: ViewerObject[] = [
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2e43", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2e47", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2e45", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2e4b", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2e49", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2efa", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2efe", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2efc", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2f02", pipeRed),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2f00", pipeRed),
	new ViewerObject("bee1b25d-57ac-4320-8ce4-b0c8a3d36f2d-000f2f4c", pipeBlue),
	new ViewerObject("bee1b25d-57ac-4320-8ce4-b0c8a3d36f2d-000f2f50", pipeBlue),
	new ViewerObject("bee1b25d-57ac-4320-8ce4-b0c8a3d36f2d-000f2f4e", pipeBlue),
	new ViewerObject("bee1b25d-57ac-4320-8ce4-b0c8a3d36f2d-000f2f54", pipeBlue),
	new ViewerObject("bee1b25d-57ac-4320-8ce4-b0c8a3d36f2d-000f2f52", pipeBlue),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2d09", pipeBlue),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2d0d", pipeBlue),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2d0b", pipeBlue),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2d11", pipeBlue),
	new ViewerObject("68620ea8-f7c7-4d0d-ad8b-e6c6e8923bac-000f2d0f", pipeBlue),
	new ViewerObject("a3194349-e73d-4dab-979a-6432ac1ce6fb-000f1fe9", ductGreen),
	new ViewerObject("a3194349-e73d-4dab-979a-6432ac1ce6fb-000f1ff6", ductGreen),
	new ViewerObject("a3194349-e73d-4dab-979a-6432ac1ce6fb-000f1fda", ductGreen),
	new ViewerObject("a3194349-e73d-4dab-979a-6432ac1ce6fb-000f1fe5", ductGreen),
	new ViewerObject("a3194349-e73d-4dab-979a-6432ac1ce6fb-000f1fce", ductGreen),
	new ViewerObject("5898cce4-888a-4e01-854a-7de0743baf9e-000f18b1", revitElementOrange),
	new ViewerObject("5898cce4-888a-4e01-854a-7de0743baf9e-000f1ab4", revitElementOrange),
	new ViewerObject("5898cce4-888a-4e01-854a-7de0743baf9e-000f1605", revitElementOrange),
]; 

export default ViewerComponent;
