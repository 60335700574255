import { RootObject } from "../components/chatHub/ChatHub";

export interface IReducerState {
	scanData: RootObject | null;
	colorableRevitIds: string[];
	speckleModelUrl: string;
	statesProgress: {
		[state: string]: { count: number; color: string };
	};
	resetUpdates: boolean;
}

const initialState = {
	scanData: null,
	colorableRevitIds: [],
	speckleModelUrl: "",
	statesProgress: {
		Designed: { count: 61, color: "#ffdc2b" },
		PreAssembled: { count: 53, color: "#f0c0a1" },
		Shipped: { count: 44, color: "#6098eb" },
		Located: { count: 31, color: "#ae77e6" },
		Installed: { count: 22, color: "#b8ed02" },
		Validated: { count: 10, color: "#21ad21" },
	},
	resetUpdates: false,
};

type ScanDataAction = { type: "SET_SCANDATA"; payload: RootObject | null; increment: number };
type UpdateUpdatesPanelAction = { type: "UPDATE_UPDATES_PANEL"; payload: null };
type setColorableRevitIdsAction = {
	type: "SET_COLORABLE_REVIT_IDS";
	payload: string[];
};
type SetSpeckleModelUrl = { type: "SET_SPECKLE_MODEL_URL"; payload: string };
type ResetAction = { type: "RESET_ACTION"; payload: boolean };
type Action =
	| ScanDataAction
	| setColorableRevitIdsAction
	| SetSpeckleModelUrl
	| UpdateUpdatesPanelAction
	| ResetAction;

export const reducer = (
	state: IReducerState = initialState,
	action: Action
) => {
	switch (action.type) {
		case "SET_SCANDATA": {
			if (action.payload?.State.name) {
				let stateName: string = action.payload?.State.name.replace(
					"-",
					""
				);
				if(action.payload?.State.name.includes("Successfully")){
					stateName = action.payload?.State.name.replace(
						"Successfully",
						""
					);
				}
				state.statesProgress[stateName].count += action.increment;
			}
			return {
				scanData: action.payload,
				colorableRevitIds: state.colorableRevitIds,
				speckleModelUrl: state.speckleModelUrl,
				statesProgress: state.statesProgress,
				resetUpdates: state.resetUpdates,
			};
		}
		case "SET_COLORABLE_REVIT_IDS": {
			return {
				scanData: state.scanData,
				colorableRevitIds: action.payload,
				speckleModelUrl: state.speckleModelUrl,
				statesProgress: state.statesProgress,
				resetUpdates: state.resetUpdates,
			};
		}
		case "SET_SPECKLE_MODEL_URL": {
			return {
				scanData: state.scanData,
				colorableRevitIds: state.colorableRevitIds,
				speckleModelUrl: action.payload,
				statesProgress: state.statesProgress,
				resetUpdates: state.resetUpdates,
			};
		}
		case "UPDATE_UPDATES_PANEL": {
			return {
				scanData: null,
				colorableRevitIds: state.colorableRevitIds,
				speckleModelUrl: state.speckleModelUrl,
				statesProgress: state.statesProgress,
				resetUpdates: state.resetUpdates,
			};
		}
		case "RESET_ACTION": {
			return {
				scanData: null,
				colorableRevitIds: state.colorableRevitIds,
				speckleModelUrl: state.speckleModelUrl,
				statesProgress: {
					Designed: { count: 50, color: "#ffdc2b" },
					PreAssembled: { count: 46, color: "#f0c0a1" },
					Shipped: { count: 42, color: "#6098eb" },
					Located: { count: 31, color: "#ae77e6" },
					Installed: { count: 22, color: "#b8ed02" },
					Validated: { count: 10, color: "#21ad21" },
				},
				resetUpdates: action.payload,
			};
		}
		default: {
			return state;
		}
	}
};
