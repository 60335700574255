const ProgressTracking = () => {
	return (
		<div
			className="col-xl-10 col-lg-10 col-md-10 col-sm-12"
			style={{ textAlign: "left" }}
		>
			<i className="fas fa-bars" style={{ paddingLeft: "1%" }}></i>
			<span style={{ marginLeft: "20px" }}>PROGRESS TRACKING</span>
		</div>
	);
};
export default ProgressTracking;
