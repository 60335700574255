import { shallowEqual, useSelector } from "react-redux";
import { IReducerState } from "../../../context/Reducer";
import CircularProgressBarRecursive from "../progress/circularProgressBarRecursive";

const SolutionsPanel = () => {
	const reducerState: any = useSelector<IReducerState>(
		(reducerState: IReducerState) => reducerState,
		shallowEqual
	);

	return (
		<div className="col-xl-12 col-md-12">
			<div className="row" style={{ margin: "4px" }}>
				<div className="card-box col-xl-4 col-md-4">
					<h4 className="header-title mt-0 m-b-0">
						DEVELOPMENT STATUS
					</h4>
					<p className="text-muted m-b-0"></p>

					<div className="widget-chart-1">
						<button
							type="button"
							className="btn btn-outline-secondary font-bold"
							style={{ borderRadius: "12px", border: "5px" }}
						>
							BY TYPICALS
						</button>
						<button
							type="button"
							className="btn btn-outline-secondary"
							style={{ borderRadius: "12px", border: "5px" }}
							disabled
						>
							BY SOLUTIONS
						</button>
						<button
							type="button"
							className="btn btn-outline-secondary"
							style={{ borderRadius: "12px", border: "5px" }}
							disabled
						>
							BY TICKETS
						</button>
					</div>
					<div className="widget-detail-1"></div>
				</div>
				<div className="card-box col-xl-4 col-md-4">
					<h4 className="header-title mt-0 m-b-0">OVERVIEW</h4>
					<p className="text-muted m-b-0"></p>
					<div
						className="widget-chart-1"
						style={{ width: "10vw", margin: "auto" }}
					>
						<CircularProgressBarRecursive
							statesProgress={reducerState.statesProgress}
							currentIndex={0}
						></CircularProgressBarRecursive>
					</div>
					<div className="widget-detail-1"></div>
				</div>
				<div className="card-box col-xl-4 col-md-4">
					<h4 className="header-title mt-0 m-b-0">
						Total Designed Solutions
					</h4>
					<h1
						id="total-count"
						className="m-b-0 m-t-30"
						style={{ color: "red", fontSize: "70px" }}
					>
						70
					</h1>
					<div className="widget-chart-1"></div>
					<div className="widget-detail-1"></div>
				</div>
			</div>
		</div>
	);
};
export default SolutionsPanel;
