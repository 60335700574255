import { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { IReducerState } from "../../context/Reducer";
import { store } from "../../context/store";
import { RootObject } from "../chatHub/ChatHub";

interface IUpdatesPanelState {
	scannedObjects: RootObject[];
	colorableRevitIds: string[];
}

const UpdatesPanel = () => {
	const [state, setState] = useState<IUpdatesPanelState>({
		scannedObjects: [],
		colorableRevitIds: [],
	});
	let scanData: any = useSelector<IReducerState>(
		(state: IReducerState) => state.scanData,
		shallowEqual
	);
	let resetNeeded: any = useSelector<IReducerState>(
		(state: IReducerState) => state.resetUpdates,
		shallowEqual
	);

	/**
	 * Adds newest ScanData to list of objects at the beginning!
	 * Deletes last entry when list has more than 4 entries.
	 */
	const updateScanDataList = () => {
		const tempScannedObjects: RootObject[] = state.scannedObjects;
		const tempColorableRevitIds: string[] = state.colorableRevitIds;
		if (tempScannedObjects.length === 4) {
			tempScannedObjects.pop();
		}
		if (tempColorableRevitIds.length === 4) {
			tempColorableRevitIds.pop();
		}
		tempColorableRevitIds.unshift(scanData.Identification);
		tempScannedObjects.unshift(scanData);
		setState({
			scannedObjects: tempScannedObjects,
			colorableRevitIds: tempColorableRevitIds,
		});
	};

	if (scanData) {
		store.dispatch({ type: "UPDATE_UPDATES_PANEL", payload: null });
		updateScanDataList();
	}

	useEffect(() => {
		if (resetNeeded) {
			setState({ scannedObjects: [], colorableRevitIds: [] });
			store.dispatch({ type: "RESET_ACTION", payload: false });
		}
		if (state.colorableRevitIds.length > 0) {
			store.dispatch({
				type: "SET_COLORABLE_REVIT_IDS",
				payload: state.colorableRevitIds,
			});
		}
	});

	return (
		<div className="card-box">
			<h3 className="mt-0 m-b-10 font-bold">Last 4 Updates (max.)</h3>
			<div className="table-responsive ">
				<table className="table mb-0" id="status-table">
					<thead>
						<tr>
							<th>Identification</th>
							<th>User</th>
							<th>Timestamp</th>
							<th>Status</th>
							<th>Location</th>
						</tr>
					</thead>
					<tbody>
						{state.scannedObjects.map(
							(scannedObject: RootObject, index: number) => {
								return (
									<tr key={index}>
										<td>{scannedObject.Identification}</td>
										<td>{scannedObject.User}</td>
										<td>{scannedObject.Date}</td>
										<td>{scannedObject.State.name}</td>
										<td>{scannedObject.Location}</td>
									</tr>
								);
							}
						)}
					</tbody>
				</table>
			</div>
		</div>
	);
};
export default UpdatesPanel;
