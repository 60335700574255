import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

interface ICircularBarProps {
  chartCount: number;
  chartColor: string;
}

const CircularProgressBar: React.FC<ICircularBarProps> = (props: any) => {
  const totalDesignedSolutions: number = 70;
  const progressNumber: number =
    (props.chartCount / totalDesignedSolutions) * 100;
  const progressText: string = progressNumber.toFixed(1) + "%";

  const progressStroleWidth: number = 7;

  return (
    <CircularProgressbarWithChildren
      value={progressNumber}
      styles={{
        path: {
          strokeLinecap: "butt",
          transformOrigin: "center center",
          stroke: props.chartColor,
          transform: "rotate(180deg)",
        },
        trail: {
          strokeLinecap: "butt",
          transformOrigin: "center center",
          stroke: "#DCDCDC",
        },
      }}
      strokeWidth={progressStroleWidth}
    >
      <strong style={{ color: props.chartColor }}>{progressText}</strong>
    </CircularProgressbarWithChildren>
  );
};

export default CircularProgressBar;
