import Config from "../../Config";

const BIMExperienceCenter = () => {
	const googleMapsUrl: string = Config.GoogleMapsUrl as string;
	return (
		<div className="col-xl-6 col-lg-6 col-md-6" style={{ display: "grid" }}>
			<div className="card-box" style={{ textAlign: "left" }}>
				<h3 className="mt-0 m-b-30 font-bold">BIM Experience Center</h3>
				<div className="ratio ratio-4x3">
					<iframe
						src={googleMapsUrl}
						frameBorder="0"
						style={{ border: 0 }}
						allowFullScreen
						aria-hidden="false"
						tabIndex={0}
					></iframe>
				</div>
			</div>
		</div>
	);
};
export default BIMExperienceCenter;
