import React from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

interface ICircularBarRecursiveProps {
  statesProgress: { [state: string]: { count: number; color: string } };
  currentIndex: number;
}

const CircularProgressBarRecursive: React.FC<ICircularBarRecursiveProps> = (
  props
) => {
  const totalDesignedSolutions: number = 70;
  let progress: number = -1;
  let color: string = "black";
  Object.keys(props.statesProgress).map((state, index) => {
    if (index === props.currentIndex) {
      progress = props.statesProgress[state].count;
      color = props.statesProgress[state].color;
    }
  });

  return (
    <CircularProgressbarWithChildren
      value={(progress / totalDesignedSolutions) * 100}
      styles={{
        path: {
          strokeLinecap: "butt",
          transformOrigin: "center center",
          stroke: color,
          transform: "rotate(180deg)",
        },
        trail: {
          strokeLinecap: "butt",
          transformOrigin: "center center",
          stroke: "#DCDCDC",
        },
      }}
      strokeWidth={4}
    >
      {props.currentIndex < 5 && (
        <div style={{ width: "85%" }}>
          <CircularProgressBarRecursive
            statesProgress={props.statesProgress}
            currentIndex={props.currentIndex + 1}
          ></CircularProgressBarRecursive>
        </div>
      )}
    </CircularProgressbarWithChildren>
  );
};

export default CircularProgressBarRecursive;
