/**
 * ViewerObject for coloring objects in the SpeckleViewer
 */
export default class ViewerObject {

    constructor(id: string, color: string){
        this._id = id;
        this._color = color;
    }
    
    /**
     * The id of the object
     */
    private _id : string;
    public get id() : string {
        return this._id;
    }
    public set id(v : string) {
        this._id = v;
    }
    
    /**
     * The color in which the object should be colored
     */
    private _color : string;
    public get color() : string {
        return this._color;
    }
    public set color(v : string) {
        this._color = v;
    }
    
}
