const CurrentProgressBar = () => {
	return (
		<div className="container m-t-5">
			<div className="row">
				<div className="widget-box-2 col-md-12">
					<div
						className="m-b-0 font-25"
						style={{ textAlign: "left" }}
					>
						75%
						<span
							className="iconify"
							data-icon="mdi:trending-up"
						></span>
					</div>

					<div className="widget-detail-2">
						<div className="text-muted m-b-0">CURRENT PROGRESS</div>
					</div>
				</div>

				<div className="progress col-md-12 px-0">
					<div
						className="progress progress-bar bg-danger"
						role="progressbar"
						aria-valuenow={75}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{ width: "75%" }}
					>
						<span className="visually-hidden">75% Complete</span>
					</div>
				</div>
			</div>
		</div>
	);
};
export default CurrentProgressBar;
