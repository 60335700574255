const Navbar = () => {
	return (
		<div className="navbar-header d-none d-md-block">
			<header id="topnav">
				<div className="topbar-main">
					<div className="container-fluid">
						<div className="logo ">
							<img
								src="assets/images/Hilti_Favicon_16x16.ico"
								width="23"
								style={{ marginRight: "10px" }}
							/>
							Information System
						</div>

						<div className="menu-extras topbar-custom"></div>

						<div className="clearfix"></div>
					</div>
				</div>

				<nav className="navbar navbar-expand-lg navbar-light bg-light">
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarNav"
						aria-controls="navbarNav"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="navbar-nav">
							<li className="nav-item">
								<a
									className="nav-link"
									href="/"
									style={{
										paddingLeft: "3vw",
										color: "black",
									}}
								>
									ALL
								</a>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="/"
									style={{ paddingLeft: 30, color: "black" }}
								>
									TIME
								</a>
							</li>
							<li className="nav-item">
								<a
									className="nav-link active"
									href="/"
									style={{ color: "red", paddingLeft: 30 }}
								>
									PROGREESS
								</a>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="/"
									style={{ paddingLeft: 30, color: "black" }}
								>
									COSTS
								</a>
							</li>
							<li className="nav-item">
								<a
									className="nav-link"
									href="/"
									style={{ paddingLeft: 30, color: "black" }}
								>
									TEAM
								</a>
							</li>
						</ul>
					</div>
				</nav>
			</header>
		</div>
	);
};
export default Navbar;
