const ReportBuilder = () => {
	var currentDate: string = new Date().toISOString().slice(0, 10);
	return (
		<section className="container">
			<form className="row">
				<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
					<div
						className="input-group mb-4"
						id="datepicker"
						style={{ paddingLeft: "1%" }}
					>
						<input
							type="date"
							className="form-control"
							id="date"
							defaultValue={currentDate}
						/>
						<button
							type="button"
							className="btn btn-sm btn-outline-dark m-l-10 m-r-15"
							style={{ color: "red" }}
						>
							BUILD REPORT
						</button>
					</div>
				</div>
			</form>
			<form className="row">
				<div className="col-lg-6">
					<div
						className="text-muted m-b-20"
						style={{
							textAlign: "left",
							paddingLeft: "1%",
							paddingTop: "1%",
						}}
					>
						Generated: Last Tuesday at 8:28 AM
					</div>
				</div>
			</form>
		</section>
	);
};

export default ReportBuilder;
