import EstimatedProgressBar from "./estimatedProgressBar";
import CurrentProgressBar from "./currentProgressBar";

const ProgressPanel = () => {
	return (
		<div className="col-xl-6 col-lg-6 col-md-6" style={{ display: "grid" }}>
			<div className="card-box">
				<h3
					className="mt-0 m-b-30 font-bold"
					style={{ textAlign: "left" }}
				>
					OVERALL PROGRESS
				</h3>
				<CurrentProgressBar />
				<EstimatedProgressBar />
			</div>
		</div>
	);
};
export default ProgressPanel;
