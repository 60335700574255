import React from "react";
import CircularProgressBar from "../progress/circularProgressBar";

interface IPiechartProps {
  header: string;
  count: number;
  color: string;
}

const Piechart: React.FC<IPiechartProps> = (props) => {
  return (
    <div className="col-xl-2  col-lg-2  col-md-2">
      <div className="card-box">
        <h4 className="header-title mt-0 m-b-0">{props.header}</h4>
        <p id={props.header + "-count"} className="text-muted m-b-0">
          {props.count}
        </p>
        <div className="widget-chart-1">
          <div
            className="widget-chart-box-1"
            style={{
              width: 100,
            }}
          >
            <CircularProgressBar
              chartCount={props.count}
              chartColor={props.color}
            />
          </div>
          <div className="widget-detail-1"></div>
        </div>
      </div>
    </div>
  );
};
export default Piechart;
