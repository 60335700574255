const EstimatedProgressBar = () => {
	return (
		<div className="container m-t-30">
			<div className="row">
				<div className="progress col-md-12 px-0">
					<div
						className="progress progress-bar bg-dark"
						role="progressbar"
						aria-valuenow={58}
						aria-valuemin={0}
						aria-valuemax={100}
						style={{ width: "58%" }}
					>
						<span className="visually-hidden">58% Complete</span>
					</div>
				</div>
				<div className="widget-box-2 col-md-12">
					<div className="widget-detail-2">
						<div className="text-muted m-b-0">
							ESTIMATED PROGRESS
						</div>
					</div>

					<div
						className="m-b-50 font-25"
						style={{ textAlign: "left" }}
					>
						58%
					</div>
				</div>
			</div>
		</div>
	);
};
export default EstimatedProgressBar;
