//React
import React from "react";
import {Provider} from "react-redux";
import {store} from "./context/store";
//Components
import Dashboard from "./components/dashboard";
//Styles
import "./App.css";

function App() {
	return (
		<Provider store={store}>
			<div className="App">
				<Dashboard />
			</div>
		</Provider>
	);
}

export default App;
